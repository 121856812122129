.sai-app-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #f5f8fa;
  flex-direction: column;
  display: flex;
}

.sai-app-container {
  height: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.sai-app-content {
  height: 100%;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  display: flex;
}

.sai-app-component {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.sai-app-nav-block {
  flex: 1;
  align-items: center;
  display: flex;
}

.sai-app-nav-block.left-nav {
  margin-right: 20px;
}

.sai-app-navbar {
  z-index: 1000;
  height: 80px;
  min-height: 80px;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: static;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.wrap-balanced {
  text-wrap: balance;
}

.sai-app-profile-text {
  margin-right: 2px;
}

.sai-app-right-nav {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.sai-app-user-wrap {
  height: 40px;
  background-color: #f5f8fa;
  border-radius: 25px;
  align-items: center;
  margin-left: 0;
  padding: 5px 5px 5px 15px;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s;
  display: flex;
}

.sai-app-user-wrap:hover {
  background-color: #e4e6e9;
}

.sai-app-nav-container {
  width: 100%;
  max-width: 1100px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.sai-app-full-logo {
  height: 32px;
}

.sai-app-small-logo {
  display: none;
}

.sai-app-nav-button {
  color: #2f363d;
  border-radius: 30px;
  align-items: center;
  margin-right: 3px;
  padding: 9px 11px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  position: relative;
}

.sai-app-nav-button:hover {
  background-color: #f5f8fa;
  border-radius: 30px;
}

.sai-app-nav-button.w--current {
  color: #6416f9;
  border-radius: 25px;
  margin-right: 3px;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
}

.sai-app-nav-button.w--current:hover {
  background-color: #e4e6e9;
  border-radius: 25px;
}

.sai-app-brandbox {
  margin-right: 30px;
}

.sai-app-brandbox:hover {
  filter: contrast(200%);
}

.sai-app-company-avatar {
  width: 30px;
  height: 30px;
  background-color: #535050;
  border-radius: 50%;
}

.sai-app-icon-wrap {
  width: 40px;
  height: 40px;
  color: #000;
  background-color: #f8f8f8;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.html-embed-2 {
  width: 24px;
  height: 24px;
}

.sai-app-heading-box {
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .sai-app-nav-block.left-nav {
    margin-right: 10px;
  }

  .sai-app-small-logo,
  .sai-app-nav-button {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sai-app-full-logo {
    display: none;
  }

  .sai-app-small-logo {
    height: 32px;
    display: block;
  }
}

@media screen and (max-width: 479px) {
  .sai-app-navbar {
    padding-left: 11px;
    padding-right: 11px;
    display: flex;
  }

  .sai-app-full-logo {
    min-height: 32px;
    min-width: 30px;
  }

  .sai-app-small-logo {
    width: 32px;
    min-height: 32px;
    min-width: 32px;
  }

  .sai-app-nav-button {
    display: none;
  }

  .sai-app-brandbox {
    margin-right: 0;
  }

  @font-face {
    font-family: 'Sora';
    src:
      url('../fonts/Sora-Variable.woff2') format('woff2'),
      url('../fonts/Sora-Variable.woff') format('woff');
  }

  .funheading {
    font-family: 'Sora-Variable', sans-serif;
  }
}

/* https://whatamesh.vercel.app/ */

.pulse-dialogue {
  background: linear-gradient(29deg, #000dbd, #0066ff, #00adf1, #a2e5ff);
  background-size: 1000% 1000%;

  -webkit-animation: dialoguePulse 7s ease-in-out infinite;
  -moz-animation: dialoguePulse 7s ease-in-out infinite;
  animation: dialoguePulse 7s ease-in-out infinite;
}

@-webkit-keyframes dialoguePulse {
  0% {
    background-position: 66% 0%;
  }
  50% {
    background-position: 35% 100%;
  }
  100% {
    background-position: 66% 0%;
  }
}
@-moz-keyframes dialoguePulse {
  0% {
    background-position: 66% 0%;
  }
  50% {
    background-position: 35% 100%;
  }
  100% {
    background-position: 66% 0%;
  }
}
@keyframes dialoguePulse {
  0% {
    background-position: 66% 0%;
  }
  50% {
    background-position: 35% 100%;
  }
  100% {
    background-position: 66% 0%;
  }
}

.card-shadow {
  box-shadow:
    2.4px 4.3px 3.5px rgba(0, 0, 0, 0.006),
    5.9px 10.3px 8.5px rgba(0, 0, 0, 0.008),
    11px 19.4px 16px rgba(0, 0, 0, 0.01),
    19.7px 34.6px 28.6px rgba(0, 0, 0, 0.012),
    36.8px 64.8px 53.5px rgba(0, 0, 0, 0.014),
    88px 155px 128px rgba(0, 0, 0, 0.02);
}

/* Style for inputs to override default ant design styles */
.force-ant-bg .ant-input {
  background-color: rgb(249 250 251); /* bg-gray-100 motsvarande i hex */
}

.score-range-slider {
  height: 50px; /* eller en annan storlek som passar ditt gränssnitt */
}

.font-mebold {
  font-weight: 550;
}

.text-xms {
  font-size: 0.825rem;
  line-height: 1.15rem;
}

.text-2xs {
  font-size: 0.5625rem;
  line-height: 0.85rem;
}

.text-spray {
  color: #303030;
}

.shade-gray {
  background-color: #ebebeb;
}

.custom-hover:hover {
  background-color: #f4f4f5 !important; /* bg-neutral-200 hover */
  color: #1f2937 !important; /* text-neutral-800 hover */
}

.custom-hover:hover {
  background-color: #e5e5e5; /* Ny hover bakgrundsfärg (bg-neutral-200) */
  color: #1f2937; /* Ny hover textfärg (text-neutral-800) */
}

.ant-btn-primary {
  background: inherit;
  border-color: transparent;
}

.ant-btn-primary:hover {
  background: inherit;
  border-color: transparent;
}

.sellto-color-bg {
  background-color: #f7f7f7;
}

.sellto-color-bg2 {
  background-color: #f1f1f1;
}

.product-changelog {
  cursor: pointer;
}

.frill-embedded {
  width: 1000px;
  height: 500px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

/* För Chrome, Safari och Opera */
.scrollbar-thin::-webkit-scrollbar {
  width: 4px; /* Gör scrollbaren tunnare */
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: lightgray; /* Gör scrollbaren ljusare */
  border-radius: 2px; /* Gör scrollbaren rundad */
  margin: 10px; /* Lägger till marginaler så att scrollbaren inte går hela vägen upp eller ner */
}

/* För Internet Explorer och Edge */
.scrollbar-thin {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: lightgray transparent; /* Gör scrollbaren ljusare i Firefox */
}
