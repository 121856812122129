.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

@-webkit-keyframes blink {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.animate-blink {
  -webkit-animation: blink 1s ease-in-out infinite;
  animation: blink 1s ease-in-out infinite;
}
