#sellto-ai-flow,
#sellto-ai-widget {
  --color-primary: #fff;
  --color-primary-variant: #d3d3d3;
  --color-secondary: #03dac6;
  --color-secondary-variant: #018786;
  --color-background: #000;
  --color-surface: #111;
  --color-error: #b00020;
  --color-on-primary: #000;
  --color-on-secondary: #000;
  --color-on-background: #fff;
  --color-on-surface: #d8d8d8;
  --color-on-error: #fff;
}
