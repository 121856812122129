#sellto-ai-flow[data-theme='light'],
#sellto-ai-widget[data-theme='light'] {
  --color-primary: #000;
  --color-primary-variant: #525252;
  --color-secondary: #03dac6;
  --color-secondary-variant: #018786;
  --color-background: #f6f2f2;
  --color-surface: #fbf9fa;
  --color-error: #b00020;
  --color-on-primary: #fff;
  --color-on-secondary: #000;
  --color-on-background: #000;
  --color-on-surface: #222222;
  --color-on-error: #fff;
}
