$blue1: #3ab4ec;
$blue2: #0342b6;
$blue3: #4f2daf;
$blue4: #281757;

$colors: $blue1, $blue2, $blue3;

.maskGradient {
  mask-image: linear-gradient(to top, black, transparent);
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
}

.movingGradient {
  background-image: linear-gradient(to right, $blue1, $blue2, $blue3, $blue4);
  background-size: 400% 100%;
  animation: bgMove 30s ease-in-out infinite;
}

@keyframes bgMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.music_wave {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  position: absolute;
  bottom: -10px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0);
}
.music_wave .wave {
  height: 100%;
  border-radius: 5px;
  mix-blend-mode: screen;
  animation: musicwave 1.5s ease-in-out infinite;
}
@keyframes musicwave {
  0%,
  100% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
}

@keyframes musicwave2 {
  0%,
  100% {
    transform: scaleY(0.75);
  }
  50% {
    transform: scaleY(1.25);
  }
}

// This is just to add some variation by making some of the waves larger
.music_wave .wave:nth-child(3n) {
  animation-name: musicwave2;
}

@for $i from 1 through 50 {
  .music_wave .wave:nth-child(#{$i}) {
    animation-delay: (calc(random(10) / 10 - $i)) + s;
    animation-duration: (calc(random(20) / 2 + 1.5)) + s;
    background-color: nth($colors, random(length($colors)));
    transform: scaleX(calc(random(10) / 10 + 0.5));
    // width: (random(30) + 5) + px;
  }
}
